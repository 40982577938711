<template>
  <div class="jobs-page-holder">
    <div class="jobs">
      <div class="main-content-wrapper">
        <div class="page-header d-flex flex-row">
          <h1 class="m-0 flex-fill font-weight-bold">Jobs</h1>
        </div>
      </div>
      <div class="main-content-wrapper">
        <JobSearchFilter />
      </div>
      <div class="main-content-wrapper table-holder">
        <JobsTable :jobs="jobs" :is-loading="isLoading" />
      </div>
      <TablePagination ref="tablePagination" :meta="paginationMeta" :total-rows="totalRows" />
      <TablePaginationInfo :meta="paginationMeta" description="jobs" />
    </div>
    <JobBatchEditFooter v-if="$store.state.selectedOrderItems.length > 0" />
    <JobsLocationSelectorModal @submitted="getJobsWithQuery" />
    <JobsStatusSelectorModal />
    <JobsRefundRequestModal />
    <SendEmailReceiptModal />
    <ChangeOrderCustomerModal />
    <ReceiptNoteModal />
    <OrderLinkModal />

    <CreateShippingOrderModal :key="$store.getters.createShippingOrderModalKey" />
    <CommentsModal />
    <OrderLensModal />
    <PrintModal />
    <JobEditModal v-if="showJobEditModal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import apiOrders from '@/api/orders';
import JobsTable from '@/components/JobsTable.vue';
import TablePagination from '@/components/TablePagination.vue';
import JobSearchFilter from '@/components/JobSearchFilter.vue';
import JobBatchEditFooter from '@/components/JobBatchEditFooter.vue';
import JobsLocationSelectorModal from '@/components/JobsLocationSelectorModal.vue';
import JobsStatusSelectorModal from '@/components/JobsStatusSelectorModal.vue';
import JobsRefundRequestModal from '@/components/JobsRefundRequestModal.vue';
import CommentsModal from '@/components/CommentsModal.vue';
import OrderLensModal from '@/components/OrderLensModal.vue';
import PrintModal from '@/components/modals/PrintModal.vue';
import CreateShippingOrderModal from '@/views/modals/CreateShippingOrder/index.vue';
import TablePaginationInfo from '@/components/TablePaginationInfo.vue';
import JobEditModal from '@/components/job-edit-modal/JobEditModal.vue';
import SendEmailReceiptModal from '@/components/SendEmailReceiptModal.vue';
import ChangeOrderCustomerModal from '@/components/ChangeOrderCustomerModal.vue';
import ReceiptNoteModal from '@/components/ReceiptNoteModal.vue';
import OrderLinkModal from '@/components/OrderLinkModal.vue';

export default {
  name: 'Jobs',
  components: {
    OrderLensModal,
    TablePaginationInfo,
    CommentsModal,
    CreateShippingOrderModal,
    JobsStatusSelectorModal,
    JobsLocationSelectorModal,
    JobsRefundRequestModal,
    JobBatchEditFooter,
    JobSearchFilter,
    TablePagination,
    JobsTable,
    PrintModal,
    JobEditModal,
    SendEmailReceiptModal,
    ChangeOrderCustomerModal,
    ReceiptNoteModal,
    OrderLinkModal,
  },
  data() {
    return {
      isLoading: false,
      totalRows: 0,
      paginationMeta: {},
    };
  },
  computed: {
    ...mapState({
      jobs: state => state.jobs,
      showJobEditModal: state => state.jobsModule.showJobEditModal,
    }),
    ...mapGetters(['selectedOrder']),
  },
  beforeMount() {
    this.$store.dispatch('setLensSuppliers');
  },
  created() {
    this.getJobsWithQuery();
    this.$store.commit('jobsModule/GET_ALL_COLORS');
  },
  methods: {
    getJobs(params = {}) {
      delete params.octopos;
      this.isLoading = true;
      apiOrders
        .getOrders({
          ...params,
          order_item_append: 'next_scheduled_date',
          product_type_append: 'lenses_type',
          action: 'list',
          no_limit: true,
        })
        .then(({ data }) => {
          this.paginationMeta = data.meta;
          this.totalRows = data.meta.total;
          this.$store.commit('SET_JOBS', data.data);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.$refs.tablePagination) {
            this.$refs.tablePagination.refreshPage();
          }
        });
    },
    getJobsWithQuery() {
      const { query } = this.$route;

      if (!query.sort_by) {
        query.sort_by = 'created_at';
        query.sort_direction = 'desc';
      }
      this.getJobs(query);
    },
  },
  watch: {
    $route() {
      this.getJobsWithQuery();
    },
  },
};
</script>
