<template >
  <div>
    <div class="filters-holder">
      <div class="d-flex flex-row mt-3">
        <div class="flex-fill">
          <b-nav class="nav--customers"
            ><b-nav-item
              v-for="(item, idx) in items"
              :key="idx"
              :active="
                (idx === 0 && !dispenseStatusSlug && !itemStatusSlug) ||
                (dispenseStatusSlug === item.slug && !itemStatusSlug) ||
                (itemStatusSlug === item.slug && !dispenseStatusSlug) ||
                (item.customFilter && $route.query.customFilter === item.slug)
              "
              @click="onChange(item)"
              ><b-badge
                class="mr-2"
                pill="pill"
                :variant="item.slug === 'late-orders' ? 'danger' : undefined"
                >{{ item.total | numeral('0a') }}</b-badge
              >{{ item.display_name }}</b-nav-item
            ></b-nav
          >
        </div>
      </div>
    </div>
    <div class="table-filter">
      <div class="filters-holder">
        <b-form @submit.stop.prevent="submit"
          ><div class="search-form">
            <b-input-group style="width: 100%">
              <ClearableInput
                name="q"
                :placeholder="searchPlaceholder"
                v-model="q"
                @input-cleared="onInputCleared"
                @enter="submit"
              >
                <template v-slot:prepend>
                  <b-input-group-text class="px-3" size="lg">
                    <i class="di-search-18"></i>
                  </b-input-group-text>
                </template>
              </ClearableInput>
            </b-input-group>

            <b-dropdown
              block="block"
              size="lg"
              menu-class="dropdown-menu--location"
              variant="floating"
              ><template v-slot:button-content
                ><span class="btn__label">Country</span
                ><span class="btn__value">{{ _.get(region, 'name', 'Any') }}</span></template
              >
              <b-dropdown-item-button @click="regionId = null"
                ><span>All Countries</span></b-dropdown-item-button
              >
              <b-dropdown-item-button
                v-for="region in $store.getters.locationSelectorRegions"
                :key="region.id"
                @click="regionId = region.id"
                ><span class="text-muted">{{ region.name }}</span></b-dropdown-item-button
              >
            </b-dropdown>
            <!-- <b-button
              variant="primary"
              type="button"
              @click="submit"
              size="lg"
              style="max-height: 49px"
              >Search</b-button
            > -->

            <b-dropdown
              variant="primary"
              :text="'Search'"
              class="search-type-dropdown"
              style="height: 49px"
            >
              <b-dropdown-item @click="updateSearchType('all')">All</b-dropdown-item>
              <b-dropdown-item @click="updateSearchType('job')">Job ID</b-dropdown-item>
              <b-dropdown-item @click="updateSearchType('order')">Order Item ID</b-dropdown-item>
            </b-dropdown>
          </div>
          <small class="text-danger" v-if="showValidationMessage && q && q.length < 3">
            Please enter at least 3 characters to search.
          </small>
          <div class="d-flex mt-2-5">
            <b-button class="bubble-wrapper" variant="filter" v-b-toggle.filters
              ><i class="di-filter-18 mr-2"></i>Filters<span
                class="bubble bubble--danger"
                v-if="totalFilters > 0"
                >{{ totalFilters }}</span
              ></b-button
            >
            <b-dropdown
              class="ml-3"
              variant="filter"
              size="sms"
              no-caret="no-caret"
              :class="{ 'btn-select-filter': !selectedFilter }"
              ><template v-slot:button-content
                ><i class="di-search-18 mr-2"></i>Saved Searches</template
              >
              <div class="text-muted text-center" v-show="savedFilterNames.length === 0">Empty</div>
              <b-dropdown-item-button
                v-for="name in savedFilterNames"
                :key="name"
                :value="name"
                @click="onSelectSavedFilter"
                :active="selectedFilter === name"
                >{{ name
                }}<i
                  class="di-remove-10 float-right"
                  @click.stop.prevent="() => removeFilter(name)"
                ></i
              ></b-dropdown-item-button>
            </b-dropdown>
            <b-button
              class="ml-auto"
              variant="clear-filter"
              @click="onClearFilter"
              v-if="totalFilters > 0"
              ><i class="di-remove-10 mr-2"></i>Clear filters</b-button
            >
          </div>
          <b-collapse id="filters" :visible="totalFilters > 0">
            <div class="border-0 pt-2-5">
              <b-row class="row--6px">
                <b-col cols="3">
                  <b-dropdown
                    block="block"
                    size="lg"
                    menu-class="dropdown-menu--location"
                    variant="floating"
                    ><template v-slot:button-content=""
                      ><span class="btn__label">Location</span
                      ><span class="btn__value">{{ selectedOutlets }}</span></template
                    >
                    <div
                      class="dropdown-item bg-white text-body"
                      v-for="outlet in filteredOutlets"
                      :key="outlet.id"
                    >
                      <b-form-checkbox class="checkbox-filter" :value="outlet.id" v-model="outletId"
                        ><span class="outlet-abbr text-uppercase">{{ outlet.abbreviation }}</span
                        ><span class="text-muted">{{ outlet.name }}</span></b-form-checkbox
                      >
                    </div>
                  </b-dropdown>
                </b-col>
                <b-col cols="3">
                  <b-dropdown
                    block="block"
                    size="lg"
                    menu-class="dropdown-menu--location"
                    variant="floating"
                    ><template v-slot:button-content
                      ><span class="btn__label">Order Status</span
                      ><span class="btn__value">{{ dispenseStatus }}</span></template
                    >
                    <div
                      class="dropdown-item bg-white text-body"
                      v-for="status in orderDispenseStatuses"
                      :key="status.slug"
                    >
                      <b-form-checkbox
                        class="checkbox-filter"
                        :value="status.slug"
                        v-model="dispenseStatusSlug"
                        ><span>{{ status.display_name }}</span></b-form-checkbox
                      >
                    </div>
                  </b-dropdown>
                </b-col>
                <b-col cols="3">
                  <b-dropdown
                    block="block"
                    size="lg"
                    menu-class="dropdown-menu--location"
                    variant="floating"
                    ><template v-slot:button-content
                      ><span class="btn__label">Item Status</span
                      ><span class="btn__value">{{ itemStatus }}</span></template
                    >
                    <div
                      class="dropdown-item bg-white text-body"
                      v-for="status in orderItemStatuses"
                      :key="status.slug"
                    >
                      <b-form-checkbox
                        class="checkbox-filter"
                        :value="status.slug"
                        v-model="itemStatusSlug"
                        ><span>{{ status.display_name }}</span></b-form-checkbox
                      >
                    </div>
                  </b-dropdown>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="form-group--floating-label mb-0" label-for="date">
                    <vc-date-picker
                      class="custom-date-picker custom-date-picker--lg"
                      mode="range"
                      v-model="createdAt"
                      :attributes="datePicker.attributes"
                      :masks="datePicker.masks"
                      :popover="{ visibility: 'click' }"
                      :input-props="{ placeholder: 'Any', class: 'placeholder--black' }"
                      is-range
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div style="display: flex; justify-content: center; align-items: center">
                          <input
                            class="bg-white border px-2 py-1 rounded w-100"
                            :value="formattedRange(inputValue)"
                            v-on="inputEvents.start"
                            placeholder="Select Date Range"
                            readonly
                          />
                        </div>
                      </template>
                    </vc-date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3" align-h="between" align-v="center">
                <b-col cols="3">
                  <b-form-checkbox
                    class="checkbox-filter"
                    :value="2"
                    :unchecked-value="null"
                    v-model="priority"
                    ><span>Priority</span></b-form-checkbox
                  >
                </b-col>
                <b-col cols="auto">
                  <SaveFilterPopover
                    target="btn-save-filter"
                    :query="currentFilters"
                    :addFilter="addJobFilter"
                  ></SaveFilterPopover>
                  <b-button variant="link" type="button" id="btn-save-filter">Save Search</b-button>
                  <b-button variant="primary" type="button" @click="submit">Apply Filters</b-button>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import orderApi from '@/api/orders';
import ClearableInput from '@/components/ClearableInput.vue';
import SaveFilterPopover from '@/components/modals/SaveFilterPopover.vue';

export default {
  name: 'JobSearchFilter',
  components: {
    ClearableInput,
    SaveFilterPopover,
  },
  data() {
    const { query } = this.$route;
    let createdStartAt = null;
    let createdEndAt = null;
    if (query.created_start_at) {
      createdStartAt = this.$moment(query.created_start_at).startOf('day').toDate();
    }
    if (query.created_end_at) {
      createdEndAt = this.$moment(query.created_end_at).startOf('day').toDate();
    }
    return {
      datePicker: {
        popover: {
          placement: 'bottom-end',
        },
        masks: {
          weekdays: 'WW',
          input: ['MMM DD'],
          data: ['DD/MM/YYYY'],
        },
        attributes: [
          {
            dot: {
              color: 'blue',
            },
            contentClass: 'text-semi-bold text-gray-950',
            dates: [new Date()],
          },
          {
            highlight: {
              class: 'bg-white',
              contentClass: 'text-semi-bold text-gray-950',
            },
            dates: [new Date()],
          },
          {
            popover: {
              visibility: 'hover',
              hideIndicator: true,
            },
            dates: [new Date()],
          },
        ],
      },
      regionId: query.region_id,
      q: null,
      items: [],
      outletId: query.outlet_id?.split(',').map(Number) || [],
      itemStatusSlug: query.item_dispense_status_id?.split(',') || [],
      dispenseStatusSlug: query.dispense_status_id?.split(',') || [],
      priority: query.priority,
      createdAt: {
        start: createdStartAt,
        end: createdEndAt,
      },
      selectedFilter: null,
      searchType: 'all',
      showValidationMessage: false,
    };
  },
  async created() {
    this.q = this.$route.query.q || null;
    this.items = await orderApi.getTotalOrders();
  },
  methods: {
    formattedRange(inputValue) {
      if (!inputValue.start && !inputValue.end) {
        return '';
      }
      const start = inputValue.start ? inputValue.start : '';
      const end = inputValue.end ? inputValue.end : '';
      return `${start} - ${end}`;
    },
    ...mapMutations({
      addJobFilter: 'ADD_JOB_FILTER',
      removeJobFilter: 'REMOVE_JOB_FILTER',
    }),
    onChange(statusObj) {
      let query = {
        ...this.$route.query,
        dispense_status_id: null,
        item_dispense_status_id: null,
        page: 1,
        customFilter: null,
        sort_direction: 'desc',
        sort_by: 'created_at',
      };

      if (statusObj.customFilter) {
        query.customFilter = statusObj.slug;
        query = {
          ...query,
          ...(statusObj.defaultQuery || {}),
        };
      } else {
        query[statusObj.filter_type] = statusObj.slug;
      }

      this.$router.push({
        name: this.$route.name,
        query,
      });
    },
    onClearFilter() {
      this.showValidationMessage = false;
      this.selectedFilter = null;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          region_id: null,
          outlet_id: null,
          dispense_status_id: null,
          item_dispense_status_id: null,
          created_start_at: null,
          created_end_at: null,
          page: 1,
          priority: null,
          customer_id: null,
          search_type: 'all',
        },
      });
    },
    submit() {
      this.showValidationMessage = true;
      if (!this.q || this.q.length >= 3) {
        this.applyFilters(this.currentFilters);
      }
    },
    onSelectSavedFilter(e) {
      this.selectedFilter = e.target.value;
      this.applyFilters(this.jobFilters[this.selectedFilter]);
    },
    applyFilters(query) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...query,
          dispense_status_id: null,
          ...(query.dispense_status_id?.length && {
            dispense_status_id: query.dispense_status_id.join(','),
          }),
          search_type: this.searchType,
          item_dispense_status_id: null,
          ...(query.item_dispense_status_id?.length && {
            item_dispense_status_id: query.item_dispense_status_id.join(','),
          }),
          outlet_id: null,
          customer_id: null,
          ...(query.outlet_id?.length && { outlet_id: query.outlet_id.join(',') }),
        },
      });
    },
    removeFilter(filterName) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure to remove filter '${filterName}'?`, {
          title: 'Please confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'px-4 py-2',
          footerClass: 'bg-white py-2 justify-content-center',
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            this.removeJobFilter(filterName);
          }
        });
    },
    updateSearchType(type) {
      this.searchType = type;
      this.submit();
    },
    onInputCleared() {
      this.searchType = 'all';
      this.submit();
    },
  },
  computed: {
    ...mapState(['outlets', 'jobFilters', 'orderDispenseStatuses', 'orderItemStatuses']),
    ...mapGetters(['locationSelectorRegions']),
    ...mapGetters('auth', ['locationSelectorOutlets']),
    filteredOutlets() {
      return (
        this.regionId
          ? this.outlets.filter(outlet => outlet.region_id === this.regionId)
          : this.outlets
      )
        .filter(
          outlet =>
            outlet.is_active || outlet.name === 'Newtown' || outlet.name === 'Union Station',
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    createdStartAt() {
      if (!this.createdAt.start) {
        return null;
      }
      return this.$moment(this.createdAt.start).utc().startOf('day').toISOString();
    },
    createdEndAt() {
      if (!this.createdAt.end) {
        return null;
      }
      return this.$moment(this.createdAt.end).utc().endOf('day').toISOString();
    },
    region() {
      if (!this.regionId) return {};
      return this.locationSelectorRegions.find(region => region.id === parseInt(this.regionId, 10));
    },
    selectedOutlets() {
      return (
        this.outlets
          .filter(({ id }) => this.outletId.includes(id))
          .map(o => o.abbreviation)
          .join(', ') || 'Any'
      );
    },
    itemStatus() {
      return (
        this.orderItemStatuses
          .filter(({ slug }) => this.itemStatusSlug.includes(slug))
          .map(s => s.display_name)
          .join(', ') || 'Any'
      );
    },
    dispenseStatus() {
      return (
        this.orderDispenseStatuses
          .filter(({ slug }) => this.dispenseStatusSlug.includes(slug))
          .map(s => s.display_name)
          .join(', ') || 'Any'
      );
    },
    totalFilters() {
      let total = 0;
      total += this.outletId.length && 1;
      total += this.itemStatusSlug.length && 1;
      total += this.dispenseStatusSlug.length && 1;
      total += this.createdAt.start && 1;
      total += Number(this.priority) === 2 && 1;
      return total;
    },
    currentFilters() {
      return {
        ...this.$route.query,
        q: this.q,
        search_type: this.searchType,
        region_id: this.regionId,
        outlet_id: this.outletId,
        item_dispense_status_id: this.itemStatusSlug,
        dispense_status_id: this.dispenseStatusSlug,
        created_start_at: this.createdStartAt,
        created_end_at: this.createdEndAt,
        priority: this.priority,
        r: this._.random(0, 100),
        page: 1,
      };
    },
    savedFilterNames() {
      return Object.keys(this.jobFilters);
    },
    converDate(date) {
      date.setDate(date.getDate());
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let formattedDate = `${year}-${month}-${day}`;
      return formattedDate + 'T00:00:00.000Z';
    },
    searchTypeLabel() {
      const labels = {
        all: 'Search All',
        job: 'Search Job ID',
        order: 'Search Order Item ID',
      };
      return labels[this.searchType];
    },
    searchPlaceholder() {
      if (this.searchType === 'job') {
        return 'Enter Job ID (e.g. DEV-D9245-ONL)';
      } else if (this.searchType === 'order') {
        return 'Enter Order ID (e.g. AU-E331982-ONL)';
      }
      return 'Search by name, phone, e-mail, or invoice number';
    },
    showSearchTypeDropdown() {
      const numberPattern = /^\d+$/;
      const hyphenPattern = /-/; // Check if the string contains a hyphen

      const shouldShowDropdown = numberPattern.test(this.q) || hyphenPattern.test(this.q);

      if (!shouldShowDropdown) {
        this.searchType = 'all'; // Set searchType to 'all' if dropdown should not be shown
      }

      return shouldShowDropdown;
    },
  },
  watch: {
    $route(to) {
      let createdStartAt = null;
      let createdEndAt = null;
      if (to.query.created_start_at) {
        createdStartAt = converDate(to.query.created_start_at);
      }
      if (to.query.created_end_at) {
        createdEndAt = converDate(to.query.created_end_at);
      }
      this.q = to.query.q || null;
      this.searchType = to.query.search_type || 'all';
      this.regionId = to.query.region_id || null;
      this.outletId = to.query.outlet_id?.split(',').map(Number) || [];
      this.itemStatusSlug = to.query.item_dispense_status_id?.split(',') || [];
      this.dispenseStatusSlug = to.query.dispense_status_id?.split(',') || [];
      this.createdAt = {
        start: createdStartAt,
        end: createdEndAt,
      };
      this.priority = to.query.priority;
    },
    currentFilters(query) {
      const isSelectedFilter = this._.some(Object.values(this.jobFilters), filter =>
        this._.isEqual(this._.omit(filter, ['r']), this._.omit(query, ['r'])),
      );
      if (!isSelectedFilter) {
        this.selectedFilter = null;
      }
    },
    q() {
      this.showValidationMessage = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.btn-select-filter {
  .btn {
    background-color: transparent !important;
    color: $gray-550;
    i {
      color: $gray-550;
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .di-remove-10 {
    font-size: 10px;
    &:hover {
      color: red;
    }
  }
}

.outlet-abbr {
  width: 50px;
  display: inline-block;
}

.checkbox-filter {
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #adb5bd;
  }
  .custom-control-label {
    &::before {
      background-color: #fff;
      top: -4px;
      border-radius: 4px !important;
    }
    &::after {
      top: -4px;
    }
    span {
      color: #1b1f26;
      font-size: 14px;
    }
  }
}

.search-type-dropdown {
  .btn {
    min-width: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
    &::after {
      margin-left: 8px;
    }
  }
}
</style>
