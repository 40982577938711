import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '../assets/layouts/Auth.vue';
import Home from '../assets/layouts/Home.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
// import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import Customers from '../views/Customers.vue';
import Jobs from '../views/Jobs.vue';
import JobsDresdenGO from '../views/JobsDresdenGO.vue';
import JobsSearch from '../views/JobsSearch.vue';
import JobsMeili from '../views/JobsMeili.vue';

import CustomersMeili from '../views/CustomersMeili.vue';
import Staffs from '../views/Staffs.vue';
import Settings from '../views/Settings.vue';
import checkAuth from './middleware/check-auth';
import Promos from '../views/Promos.vue';
import NewsletterUsers from '../views/NewsletterUsers.vue';

Vue.use(VueRouter);

const homepage = 'customers';

const routes = [
  {
    path: '',
    component: Home,
    beforeEnter: checkAuth,
    children: [
      {
        path: '/',
        redirect: { name: 'customers' },
        // name: 'dashboard',
        // component: Dashboard,
        // meta: {
        //   breadcrumb: [
        //     {
        //       text: 'Home',
        //       to: {
        //         name: 'dashboard',
        //       },
        //     },
        //   ],
        // },
      },
      {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Customers',
              to: {
                name: 'customers',
              },
            },
          ],
        },
      },
      {
        path: '/jobs',
        name: 'jobs',
        component: Jobs,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Jobs',
              to: {
                name: 'jobs',
              },
            },
          ],
        },
      },
      {
        path: '/jobs-search',
        name: 'jobs-search',
        component: JobsSearch,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Jobs Search',
              to: {
                name: 'jobs-search',
              },
            },
          ],
        },
      },

      {
        path: '/jobs-dresdengo',
        name: 'jobs-dresdengo',
        component: JobsDresdenGO,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'DresdenGO Jobs',
              to: {
                name: 'jobs-dresdengo',
              },
            },
          ],
        },
      },


      {
        path: '/promos',
        name: 'promos',
        component: Promos,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Promos & Campaigns',
              to: {
                name: 'promos',
              },
            },
          ],
        },
      },
      {
        path: '/jobs-meili',
        name: 'jobs-meili',
        component: JobsMeili,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Jobs MEILI',
              to: {
                name: 'jobs-meili',
              },
            },
          ],
        },
      },
      {
        path: '/customers-meili',
        name: 'customers-meili',
        component: CustomersMeili,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Customers MEILI',
              to: {
                name: 'customers-meili',
              },
            },
          ],
        },
      },
      {
        path: '/staffs',
        name: 'staffs',
        component: Staffs,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/newsletter-users',
        name: 'newsletter-users',
        component: NewsletterUsers,
        meta: {
          breadcrumb: [
            {
              text: 'Home',
              to: {
                name: homepage,
              },
            },
            {
              text: 'Newsletter Subscribers',
              to: {
                name: 'newsletter-users',
              },
            },
          ],
        },
      },
    ],
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
      },
      {
        path: '/password-reset/:token',
        name: 'password-reset',
        component: ResetPassword,
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  const currentUser = Vue.auth.user();
  if (window.analytics && currentUser.id) {
    window.analytics.identify(currentUser.id, currentUser);
  }
});

export default router;
