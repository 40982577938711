<template>
  <b-table
    :items="processedJobs"
    :fields="fields"
    :busy="isLoading"
    class="jobs-table"
    style="text-align: left"
    head-variant="light"
    responsive="sm"
    show-empty
    no-local-sorting
    @sort-changed="onSortChanged"
  >
    <template v-slot:table-busy>
      <div class="text-center text-light my-2">
        <b-spinner class="align-middle" />
      </div>
    </template>
    <template v-slot:table-colgroup="scope">
      <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
    </template>
    <template v-slot:head(created_at)="data">
      <span>{{ data.label }}</span>
      <i
        class="di-filled-arrow-up-8 ml-2"
        v-if="sortBy === 'created_at' && sortDirection === 'desc'"
      />
      <i
        class="di-filled-arrow-down-8 ml-2"
        v-if="sortBy === 'created_at' && sortDirection === 'asc'"
      />
    </template>
    <template v-slot:cell(collapse)="data">
      <b-button
        variant="icon"
        @click="() => expandJobDetail(data)"
        class="btn-icon--collapse"
        :class="data.detailsShowing ? '' : 'collapsed'"
      >
        <i class="di-arrow-up-12" v-if="data.detailsShowing" />
        <i class="di-arrow-down-12" v-if="!data.detailsShowing" />
      </b-button>
    </template>
    <template v-slot:cell(priority)="data">
      <b-button
        variant="icon"
        @click="setPriority(data.item)"
        class="btn-icon--priority"
        :class="data.item.priority === 2 ? 'urgent' : ''"
      >
      </b-button>
    </template>

    <template v-slot:cell(vend_invoice_number)="data">
      <div style="width: 125px">
        <span>{{ data.item.vend_invoice_number }}</span>

        <div v-if="data.item.related_orders">
          <div v-for="order in data.item.related_orders" :key="order.id">
            <b-link
              :href="`/jobs?q=${
                order.related_order.vend_invoice_number == null
                  ? order.related_order.uid
                  : order.related_order.vend_invoice_number
              }`"
              target="_blank"
              style="color: #2b82fc"
            >
              <div style="display: flex; align-items: center; justify-content: start; height: 18px">
                <div>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L5.9375 11.9375L8.625 9.25H0V0H1.5V7.75H8.625L5.9375 5.0625L7 4L11.5 8.5L7 13Z"
                      fill="#2B82FC"
                    />
                  </svg>
                </div>
                <p
                  style="
                    color: #2b82fc;
                    margin-left: 2px;
                    margin-bottom: 0px;
                    margin-top: 6px;
                    font-size: 12px;
                  "
                >
                  {{
                    order.related_order.vend_invoice_number == null
                      ? order.related_order.uid
                      : order.related_order.vend_invoice_number
                  }}
                </p>
              </div>
            </b-link>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:cell(customer)="data">
      <a
        v-if="data && data.item.customer && data.item.customer_id"
        :href="createIOClassicLinkCustomer(data.item.customer_id)"
        target="_blank"
        class="font-weight-medium"
        style="text-decoration: none; color: #000"
      >
        <span v-html="customerName(data)" />
      </a>
      <span v-else v-html="customerName(data)" class="font-weight-medium" />
    </template>

    <template v-slot:cell(total_amount)="data">
      <span>${{ data.item.total_amount.toFixed(2) }}</span>
    </template>
    <template v-slot:cell(dispense_status_id)="data">
      <b-badge
        v-if="
          data.item.dispense_status_id === 'in-progress' ||
          data.item.dispense_status_id === 'cancelled'
        "
        class="status-badge"
        pill
        :variant="
          getStatusVariant(
            isAllItemsRefunded(data.item) ? 'refunded' : data.item.dispense_status_id,
          )
        "
      >
        {{ data.item.dispenseStatusDisplay }}
      </b-badge>
      <b-dropdown
        v-else
        class="status-dropdown"
        menu-class="dropdown-menu--status"
        toggle-class="btn--badge-pill"
        :variant="getStatusVariant(data.item.dispense_status_id)"
      >
        <template v-slot:button-content>
          {{ data.item.dispenseStatusDisplay }}
        </template>
        <b-dropdown-item-button
          v-for="status in selectableDispenseStatuses"
          :key="status.slug"
          @click="onStatusChange(data.item.id, status.slug)"
        >
          <span :class="getStatusVariant(status.slug)" class="dot dot--sm dot--secondary mr-2" />
          <span>{{ status.display_name }}</span>
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
    <template v-slot:cell(created_at)="data">
      <ToggleText>
        <template v-slot:normal-text>
          {{ data.item.created_at | moment('timezone', getTimeZone(data.item), 'Do MMM YYYY, ha') }}
        </template>
        <template v-slot:hidden-text>
          {{ data.item.created_at | moment('timezone', getTimeZone(data.item), 'Do MMM YYYY, ha') }}
        </template>
      </ToggleText>
    </template>
    <template v-slot:cell(notes_count)="data">
      <b-button
        variant="icon-text-transparent"
        style="display: flex; align-items: center; justify-content: center; padding: 0"
        class="p-0"
        @click="openCommentsModal(data.item)"
      >
        <span class="icon" :class="{ active: data.item.notes_count }">
          <i class="di-comment-18" />
        </span>
        <span class="text" style="margin-left: 3px">
          {{ data.item.notes_count }}
        </span>
      </b-button>
    </template>
    <template v-slot:cell(select)="data">
      <b-button
        variant="icon"
        @click="handleSelectOrder(data.item)"
        class="btn-icon--select"
        :class="{ selected: areAllItemsSelected(data.item) }"
      />
    </template>
    <template v-slot:row-details="data">
      <v-wait :for="`${data.item.id}-detail`">
        <template slot="waiting">
          <div class="text-center py-5">
            <b-spinner />
          </div>
        </template>
        <div class="row-details">
          <div class="d-flex flex-column">
            <div class="rd-body">
              <OrderItemGroup
                v-for="group in data.item.item_groups"
                :key="group.id"
                :order="data.item"
                :group="group"
              ></OrderItemGroup>
            </div>
            <div class="divider mt-1 mb-3 mx-3"></div>
            <div class="order-group">
              <h6 class="text-uppercase order-group-heading">Shipping</h6>
              <OrderItemShipmentDetail :order="data.item" />
            </div>

            <div class="order-group" v-if="data.item.coupons">
              <h6 class="text-uppercase order-group-heading">Coupons</h6>
              <OrderCoupons :order="data.item" />
            </div>

            <div class="order-group" v-if="data.item.payments && isOctoposOrder(data.item)">
              <h6 class="text-uppercase order-group-heading">Payments</h6>
              <OrderPayments :order="data.item" />
            </div>

            <div class="divider mt-1 mb-3 mx-3"></div>
            <div class="rd-footer ml-2-5">
              <b-button
                class="btn-white-outline-light mr-3 mb-3"
                @click="openCreateShippingOrderModal(data.item)"
                v-if="isShppingOrder && !data.item.shipment"
                :disabled="$wait.is(`${data.item.id}-create-shipping-order`) && !isDraft"
              >
                <b-spinner
                  small
                  v-if="$wait.is(`${data.item.id}-create-shipping-order`)"
                  class="mr-2"
                />
                Create Shipping Order
              </b-button>
              <b-button
                class="btn-white-outline-light mr-3 mb-3"
                @click="openPrintModal(data.item)"
              >
                Print
              </b-button>
              <b-link
                v-if="data.item.customer"
                class="btn btn-white-outline-light mr-3 mb-3"
                :href="`${ioUrl}#/customer/${data.item.customer.id}`"
                target="_blank"
              >
                View Profile
              </b-link>
              <b-button
                class="btn-white-outline-light mr-3 mb-3"
                @click="handleClickRefund(data.item) || !isDraft"
                v-show="data.item.is_refundable"
              >
                {{ getRefundType(data.item) }}
              </b-button>
              <b-button
                class="btn-white-outline-light mr-3 mb-3"
                @click="openSendMessageModal(data.item)"
                >Send a Message
              </b-button>

              <b-button class="btn-white-outline-light mr-3 mb-3" @click="viewReceipt(data.item)"
                >View Receipt
              </b-button>

              <b-button
                class="btn-white-outline-light mb-3 mr-3"
                @click="sendEmailReceipt(data.item)"
                >Email Receipt
              </b-button>

              <b-button
                class="btn-white-outline-light mb-3 mr-3"
                v-if="isCustomerChange(data.item)"
                @click="changeCustomer(data.item)"
                >Change Customer
              </b-button>

              <b-button
                class="btn-white-outline-light mb-3 mr-3"
                @click="editReceiptNote(data.item)"
                >{{ isReceiptNoteExist(data.item) ? 'Edit' : 'Create' }} Receipt Note
              </b-button>

              <b-button class="btn-white-outline-light mb-3 mr-3" @click="linkOrder(data.item)"
                >Link Order
              </b-button>

               <b-button class="btn-white-outline-light mr-3 mb-3" @click="showCreatePaymentModal(data.item)"
                >Create Payment
              </b-button>
            </div>
          </div>
        </div>
      </v-wait>
    </template>
  </b-table>
</template>

<script>
import sha256 from 'crypto-js/sha256';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import apiOrders from '@/api/orders';
import apiNotes from '@/api/notes';
import apiFiles from '@/api/files';
import ToggleText from './ToggleText.vue';
import OrderItemGroup from './OrderItemGroup.vue';
import OrderItemShipmentDetail from './OrderItemShipmentDetail.vue';
import OrderCoupons from './OrderCoupons.vue';
import OrderPayments from './OrderPayments.vue';
import axios from 'axios';

const OTHERS_GROUP_NAME = 'front-end-others';

export default {
  name: 'JobsTable',
  components: {
    ToggleText,
    OrderItemGroup,
    OrderItemShipmentDetail,
    OrderCoupons,
    OrderPayments,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      fields: [
        {
          key: 'collapse',
          label: '',
        },
        {
          key: 'customer',
          label: 'Customer',
          bold: true,
          width: '150px',
        },
        {
          key: 'priority',
          label: 'Priority',
        },
        {
          key: 'vend_invoice_number',
          label: 'Invoice Number',
        },
        {
          key: 'total_amount',
          label: 'Total ($)',
        },
        {
          key: 'dispense_status_id',
          label: 'Status',
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'notes_count',
          label: '',
        },
        {
          key: 'select',
          label: '',
        },
      ],
    };
  },
  async mounted() {
    this.$root.$on('reloadExpandedJob', orderId => {
      this.updateOrderByOrderId(orderId);
    });
  },
  computed: {
    sortBy() {
      return this.$route.query.sort_by || 'created_at';
    },
    ...mapState({
      orderDispenseStatuses: state => state.orderDispenseStatuses,
      currentUser: state => state.auth.user,
    }),
    ...mapGetters(['selectableDispenseStatuses', 'selectedOrderItemIds']),
    currentUserInAU() {
      return this._.get(this.currentUser, 'outlet.region.country_code') === 'AU';
    },
    isShppingOrder() {
      return this._.get(this.currentUser, 'outlet.region.country_code') !== 'NZ';
    },
    processedJobs() {
      return this.jobs.map(job => {
        // eslint-disable-next-line
        job.dispenseStatusDisplay = this.getStatusDisplayName(
          this.isAllItemsRefunded(job) &&
            job.dispense_status_id !== 'refunded-transaction' &&
            job.dispense_status_id !== 'voided'
            ? 'refunded'
            : job.dispense_status_id,
          this.orderDispenseStatuses,
        );
        return job;
      });
    },
    sortDirection() {
      return this.$route.query.sort_direction || 'desc';
    },
    isDraft(order) {
      const payment_status = this._.get(order, 'payment_status_id');
      if (payment_status === 'parked') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getTimeZone(order) {
      const region_id = this._.get(order, 'origin_outlet.region_id');
      let defaultTimeZone = 'Australia/Sydney';
      if (region_id === 5) {
        defaultTimeZone = 'America/Toronto';
      }

      return defaultTimeZone;
    },
    isCanadaOrder(order) {
      return this._.get(order, 'origin_outlet.region.country_code') === 'CA';
    },
    ...mapActions(['setSelectedOrderLevel']),
    ...mapMutations({
      setSelectedOrderId: 'SET_SELECTED_ORDER_ID',
      setSelectedRefundType: 'SET_TEMP_REFUND_TYPE',
      updateOrder: 'UPDATE_JOB_IN_JOBS',
    }),
    ...mapMutations('commentModal', {
      setSelectedUpdateNote: 'SET_SELECTED_UPDATE_NOTE',
      setSelectedNoteOrder: 'SET_SELECTED_NOTE_ORDER',
    }),
    onSortChanged(ctx) {
      const query = {
        sort_by: ctx.sortBy,
        sort_direction: this.sortDirection === 'desc' ? 'asc' : 'desc',
        page: 1,
      };
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },
    async openCreateShippingOrderModal(order) {
      const updatedOrder = await this.updateOrderByOrderId(
        order.id,
        `${order.id}-create-shipping-order`,
      );
      this.$store.dispatch('addressModal/openCreateShippingOrderModal', {
        order: updatedOrder,
        vm: this,
      });
    },
    openPrintModal(order) {
      axios
        .get(`${process.env.VUE_APP_NEW_API_URL}/staff/job-tray-signed-url/${order.id}`)
        .then(({ data: signedUrl }) => {
          window.open(signedUrl, '_blank');
        });
    },
    openCommentsModal(order) {
      let title = this.getCustomerName(order.customer);
      title += order.vend_invoice_number ? ` — ${order.vend_invoice_number}` : '';
      this.$store.commit('commentModal/SHOW_COMMENT_MODAL', {
        vm: this,
        title,
        createCommentsCallback: async noteContent => {
          const { comment, file } = noteContent;
          const form = { content: comment };

          const uploadedFile = await apiFiles.createFile(file);
          if (uploadedFile) {
            form.files = [uploadedFile.data.data.id];
          }
          const api = apiNotes.createOrderNote(order.id, form).then(() => {
            this.updateOrderByOrderId(order.id);
          });

          return api;
        },
        fetchCommentsCallback: () => apiNotes.getOrderNotes(order.id, 'order'),
        fetchOrderCallback: () => apiOrders.getOrder(order.id, 'order'),
        removeCommentCallback: noteId => apiNotes.deleteOrderNote(order.id, noteId, 'order'),
        isOnlineOrder: this.isOnlineOrder(order),
      });
    },
    getRefundType(order) {
      const refundType = this._.get(order, 'refund_type');
      if (typeof refundType !== 'undefined' && refundType !== null && refundType === 'void') {
        return 'Void';
      }
      return 'Refund';
    },
    onStatusChange(orderId, slug) {
      apiOrders
        .updateOrderDispenseStatus(orderId, {
          dispense_status_id: slug,
        })
        .then(({ data: { data: job } }) => {
          this.$store.commit('UPDATE_JOB_IN_JOBS', job);
        });
    },
    createVendLink(order) {
      return `${order.vend_base_url}history?receipt_number=${order.vend_invoice_number}`;
    },
    createIOClassicLink(order) {
      return `${this.ioUrl}#/jobs?job_screen_tab=all&filter=${order.vend_invoice_number}&dispense_outlet_id=all`;
    },
    createIOClassicLinkCustomer(customerId) {
      return `${this.ioUrl}#/customer/${customerId}`;
    },
    setPriority(order) {
      const selectedJob = this._.clone(order);
      selectedJob.priority = order.priority === 1 ? 2 : 1;
      this.$store.commit('UPDATE_JOB_IN_JOBS', selectedJob);
      apiOrders
        .updateOrderPriority(order.id, selectedJob.priority)
        .then(() => {
          this.$store.dispatch('showSuccessAlert', 'Job status is successfully changed.');
        })
        .catch(error => {
          this.$store.commit('UPDATE_JOB_IN_JOBS', order);
          this.$store.dispatch('showErrorAlert', error);
        });
    },
    handleClickRefund(order) {
      this.setSelectedRefundType('full');
      this.setSelectedOrderId(order.id);
      this.$bvModal.show('jobs-refund-request-modal');
    },
    openSendMessageModal(order) {
      this.$store.commit('SET_SELECTED_ORDER_ID', order.id);
      this.$nextTick(() => {
        this.$bvModal.show('send-message-modal');
      });
    },
    areAllItemsSelected(order) {
      return (
        order.item_groups.length &&
        order.item_groups.every(g =>
          g.order_items.every(item => this.selectedOrderItemIds.includes(item.id)),
        )
      );
    },
    handleSelectOrder(order) {
      this.setSelectedOrderLevel({
        order,
        selected: this.areAllItemsSelected(order),
      });
    },
    customerName(row) {
      const { first_name, last_name } = row.value;

      if (first_name || last_name) {
        return `${first_name} ${last_name}`;
      }
      // Guest
      const { customer_first_name = '', customer_last_name = '' } = row.item.order_billing || {};
      let name = '';
      if (customer_first_name || customer_last_name) {
        name += `${customer_first_name} ${customer_last_name}<br />`;
      }
      name += '(Guest)';

      return name;
    },
    isAllItemsRefunded(order) {
      const hasNoGroupItems = !!order.no_group_items?.length;

      const itemGroupAllItemsRefundedOrEmpty = order.item_groups.every(g =>
        g.order_items.every(i => i.quantity === -1),
      );

      if (!hasNoGroupItems) return itemGroupAllItemsRefundedOrEmpty;

      return (
        order.no_group_items.every(orderItem => orderItem.quantity === -1) &&
        itemGroupAllItemsRefundedOrEmpty
      );
    },
    async expandJobDetail(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.updateOrderByOrderId(row.item.id);
      }
    },
    async updateOrderByOrderId(orderId, customWait) {
      this.$wait.start(customWait || `${orderId}-detail`);
      const response = await apiOrders.getOrder(orderId);
      const order = response.data.data;
      order.notes_count = order.notes?.length;

      if (order.no_group_items?.length && order.item_groups && Array.isArray(order.item_groups)) {
        const existingOthersGroup = order.item_groups.find(
          group => group.type === OTHERS_GROUP_NAME,
        );

        if (!existingOthersGroup) {
          order.item_groups.push({
            name: '', // don't show label for other
            type: OTHERS_GROUP_NAME, // this is a group that's only created on the frontend
            order_items: order.no_group_items,
          });
        }
      }

      this.$wait.end(customWait || `${orderId}-detail`);

      this.updateOrder(order);

      return order;
    },
    createHash(orderId, customerId) {
      const inputString = customerId
        ? `DRESDEN_${orderId}-${customerId}_l5jIveEz5QrSWwEM5uKm`
        : `DRESDEN_${orderId}-_l5jIveEz5QrSWwEM5uKm`;
      return sha256(inputString).toString();
    },
    viewReceipt(row) {
      const hash = this.createHash(row.id, row.customer_id);
      const url = `${process.env.VUE_APP_WC_API_URL}/receipt/show/${row.id}/${hash}`;
      window.open(url, '_blank');
    },
    isOnlineOrder(row) {
      return row.vend_invoice_number.includes('ONL');
    },
    isOctoposOrder(row) {
      return row.is_octopos_order;
    },
    isCustomerChange(row) {
      let customerChange = false;
      if (row.is_octopos_order || row.vend_invoice_number.includes('ONL')) {
        customerChange = true;
      }

      return customerChange;
    },
    sendEmailReceipt(row) {
      this.setSelectedOrderId(row.id);
      this.$bvModal.show('send-email-receipt-modal');
    },
    changeCustomer(row) {
      this.setSelectedOrderId(row.id);
      this.$bvModal.show('change-order-customer-modal');
    },
    linkOrder(row) {
      this.setSelectedOrderId(row.id);
      this.$bvModal.show('order-link-modal');
    },
    isReceiptNoteExist(row) {
      if (row.notes && row.notes.length > 0) {
        const receiptNote = row.notes.find(
          note => note.content && note.content.includes('Receipt Note:'),
        );
        if (receiptNote) {
          return true;
        }
      }

      return false;
    },
    editReceiptNote(row) {
      let receiptNote = null;
      if (this.isReceiptNoteExist(row)) {
        if (row.notes && row.notes.length > 0) {
          receiptNote = row.notes.find(
            note => note.content && note.content.includes('Receipt Note:'),
          );
          this.setSelectedUpdateNote(receiptNote);
        }
      } else {
        this.setSelectedUpdateNote(null);
      }
      this.setSelectedNoteOrder(row.id);
      this.$bvModal.show('receipt-note-modal');
    },
  },
};
</script>